import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

import { InputValue, OptionsValues, ProductId } from './products';

const CartItem = t.intersection([
  t.type({
    id: t.number,
    productId: ProductId,
    optionsValues: OptionsValues,
    quantity: t.number,
    createdAt: DateFromISOString,
    updatedAt: DateFromISOString,
  }),
  t.partial({
    propertiesValues: t.union([t.record(t.string, InputValue), t.null, t.undefined]),
  }),
]);
type CartItem = t.TypeOf<typeof CartItem>;

export const Cart = t.strict({
  userId: t.number,
  items: t.array(CartItem),
  createdAt: DateFromISOString,
  updatedAt: DateFromISOString,
});
export type Cart = t.TypeOf<typeof Cart>;

export const AddCartItem = t.strict({
  productId: t.number,
  optionsValues: OptionsValues,
  quantity: t.number,
});
export type AddCartItem = t.TypeOf<typeof AddCartItem>;
