import 'core-js/features/array/find';
import 'core-js/features/array/flat';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/array/values';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/string/starts-with';
import 'core-js/features/symbol';
import URL from 'url-polyfill';

if (typeof window !== 'undefined' && typeof window.URL !== 'function') {
  window.URL = URL;
}
