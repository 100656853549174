import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import NextHead from 'next/head';
import React, { ReactNode } from 'react';
import { LocalizationProvider } from './LocalizationProvider';
import { UserTheme, defaultTheme, getTheme } from './theme';
type MuiProviderProps = {
  children: ReactNode;
  userTheme: UserTheme | undefined;
};
export const MUIProvider = ({
  children,
  userTheme
}: MuiProviderProps) => {
  const theme = React.useMemo(() => {
    return typeof getTheme === 'function' ? getTheme(userTheme) : defaultTheme || {};
  }, [userTheme]);
  return <>
      <NextHead data-sentry-element="NextHead" data-sentry-source-file="index.tsx">
        {userTheme && <meta name="theme-color" content={userTheme.primaryColor} />}
      </NextHead>
      <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="index.tsx" />
      <StyledEngineProvider injectFirst data-sentry-element="StyledEngineProvider" data-sentry-source-file="index.tsx">
        <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="index.tsx">
          <LocalizationProvider data-sentry-element="LocalizationProvider" data-sentry-source-file="index.tsx">{children}</LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>;
};