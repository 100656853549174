import { NextIntlClientProvider, useLocale, useTranslations } from 'next-intl';
import { getLocale, getTranslations } from 'next-intl/server';
import { ReactNode, useCallback } from 'react';
import { Locale as LocaleType, localeToTimeZone } from 'i18n/config';
export type Locale = LocaleType;
export const I18nProvider = ({
  children,
  messages,
  locale
}: {
  children: ReactNode;
  messages: React.ComponentProps<typeof NextIntlClientProvider>['messages'];
  locale: React.ComponentProps<typeof NextIntlClientProvider>['locale'];
}) => {
  return <NextIntlClientProvider messages={messages} timeZone={localeToTimeZone[locale as LocaleType] || 'Europe/Paris'} locale={locale} data-sentry-element="NextIntlClientProvider" data-sentry-component="I18nProvider" data-sentry-source-file="i18n.tsx">
      {children}
    </NextIntlClientProvider>;
};
export const getServerSideTranslations = async (locale: string) => {
  return {
    messages: (await import(`../../public/locales/${locale}/main.json`)).default
  };
};
const localeToCountry: Record<LocaleType, 'FR' | 'GB'> = {
  en: 'GB',
  fr: 'FR'
};
export type TFunction = (key: `main:${NextIntlTranslationKeys}`, options?: Parameters<ReturnType<typeof useTranslations>>[1]) => string;
export const useServerTranslation = async () => {
  const [t, locale] = await Promise.all([getTranslations(), getLocale()]);
  return {
    t: (key: `main:${NextIntlTranslationKeys}`) => t(key.replace('main:', '') as NextIntlTranslationKeys),
    i18n: {
      language: locale as LocaleType,
      country: localeToCountry[locale as LocaleType]
    }
  };
};
export const useTranslation = () => {
  const t = useTranslations();
  const locale = useLocale();
  return {
    t: useCallback<TFunction>((key, options) => t(key.replace('main:', '') as NextIntlTranslationKeys, options), [t]),
    i18n: {
      language: locale as LocaleType,
      country: localeToCountry[locale as LocaleType]
    }
  };
};
type TransProps = {
  i18nKey: `main:${NextIntlTranslationKeys}`;
  components: Record<string, (children: ReactNode) => ReactNode>;
};
export const Trans = ({
  i18nKey,
  components
}: TransProps) => {
  const t = useTranslations();
  return t.rich(i18nKey.replace('main:', '') as NextIntlTranslationKeys, components);
};