import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiCircularProgress from '@mui/material/CircularProgress';
import classNames from 'clsx';
import React from 'react';
import styles from './Button.module.css';
import { LinkBehavior, LinkBehaviorProps } from './LinkBehavior';
type MarginClassName = 'noneMargin' | 'denseMargin' | 'normalMargin';
type ButtonProps = Omit<MuiButtonProps, 'component'> & {
  margin?: 'none' | 'dense' | 'normal';
  loading?: boolean;
  component?: React.ElementType;
};
const Button = ({
  children,
  margin = 'none',
  className,
  disabled = false,
  loading = false,
  ...props
}: ButtonProps) => {
  const marginClassName = `${margin}Margin` as MarginClassName;
  return <MuiButton className={classNames(styles.root, styles[marginClassName], className)} disabled={loading || disabled} {...props} data-sentry-element="MuiButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {children}
      {loading && <span className={styles.loader}>
          <MuiCircularProgress color={props.color === 'secondary' ? 'secondary' : 'primary'} size={25} />
        </span>}
    </MuiButton>;
};
export default Button;
export const ButtonAsLink = (props: ButtonProps & LinkBehaviorProps) => <Button {...props} component={LinkBehavior} data-sentry-element="Button" data-sentry-component="ButtonAsLink" data-sentry-source-file="Button.tsx" />;