import React from 'react';

import { HttpError } from 'client/hooks/useAPI';

import { ErrorCoded } from 'client/utils/error';
import { useTranslation } from 'client/utils/i18n';

import applicationInsights, { SeverityLevel } from 'utils/application-insights';
import { assertNeverSafe } from 'utils/type';

import useNotifications from './useNotifications';

function useErrorNotification() {
  const { t } = useTranslation();
  const { notify } = useNotifications();

  const notifyError = React.useCallback(
    (error: unknown) => {
      let newError: Error;
      if (error instanceof ErrorCoded) {
        switch (error.code) {
          case 'newsletter_unsubscribe_invalid_email':
          case 'newsletter_unsubscribe_unauthorized':
          case 'user_create_already_exists':
          case 'promo_code_not_found':
          case 'promo_code_not_active':
          case 'promo_code_not_started':
          case 'promo_code_expired':
          case 'promo_code_max_used':
          case 'promo_code_max_used_for_user':
          case 'promo_code_minimum_amount_not_reached':
          case 'promo_code_error_validation':
          case 'order_create_bad_request':
          case 'order_create_email_required':
          case 'login_invalid_email':
          case 'login_invalid_password':
          case 'login_password_does_not_match':
          case 'password_reset_invalid_token':
          case 'request_failed':
          case 'request_400':
          case 'request_403':
          case 'request_404':
          case 'request_405':
          case 'request_407':
          case 'request_408':
          case 'request_409':
          case 'request_500':
          case 'request_parsing_response_failed':
          case 'request_type_check_response_failed': {
            console.error(error);
            applicationInsights.trackTrace(
              'Error Notification: handled',
              { error: error.toString() },
              SeverityLevel.Information,
            );

            return notify(t(`main:${error.code}`), 'error');
          }
          default: {
            newError = new Error(`Error not handled: ${error.code}`);
          }
        }
      } else if (error instanceof HttpError) {
        switch (error.errorCode) {
          case 'request_type_check_response_failed':
          case 'request_400':
          case 'request_401':
          case 'request_402':
          case 'request_403':
          case 'request_404':
          case 'request_405':
          case 'request_407':
          case 'request_408':
          case 'request_409':
          case 'request_500':
          case 'request_502':
          case 'request_503':
          case 'request_504':
          case 'page_regeneration_failed':
          case 'promo_code_not_found':
          case 'user_create_already_exists':
          case 'order_create_email_required':
          case 'request_failed': {
            console.error(error);
            applicationInsights.trackTrace(
              'Error Notification: handled',
              { error: error.toString() },
              SeverityLevel.Information,
            );

            return notify(t(`main:${error.errorCode}`), 'error');
          }
          default: {
            assertNeverSafe(error.errorCode);
            newError = new Error(`Error not handled: ${error.errorCode}`);
          }
        }
      } else if (error !== undefined && error !== null) {
        switch (typeof error) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'object':
          case 'function':
            newError = new Error(`Error not handled: ${error!.toString()}`);
            break;
          default:
            newError = new Error(`Error not handled: ${error}`);
            break;
        }
      } else {
        newError = new Error(`Error not handled: ${error}`);
      }

      console.error(newError);
      applicationInsights.trackTrace(
        'Error Notification: NOT handled',
        { error: newError.toString() },
        SeverityLevel.Error,
      );

      return notify(t('main:error_500'), 'error');
    },
    [notify, t],
  );

  return { notifyError };
}

export default useErrorNotification;
