import * as t from 'io-ts';
import fetch from 'isomorphic-fetch';
import { getSession } from 'next-auth/react';
import { stringify } from 'qs';

import applicationInsights, { SeverityLevel } from '../../utils/application-insights';
import { getErrorMessages, validate } from '../../utils/type';
import { ErrorCoded } from './error';

interface StringifyUrlOptions {
  origin?: string;
  pathname?: string;
  queryParams?: any;
}
export function stringifyUrl({ origin = '', pathname = '', queryParams }: StringifyUrlOptions) {
  return `${origin}${pathname}${stringify(queryParams, {
    arrayFormat: 'brackets',
    indices: false,
    addQueryPrefix: true,
  })}`;
}

type ErrorCodeFromApi = { code: string | number; message?: string };
function hasCode(data: unknown): data is ErrorCodeFromApi {
  return (
    !!data &&
    typeof data === 'object' &&
    (typeof (data as ErrorCodeFromApi).code === 'number' ||
      typeof (data as ErrorCodeFromApi).code === 'string')
  );
}

export class HttpError extends ErrorCoded {
  public readonly status: string | number;
  public readonly responseText: string;
  public response?: Response;

  constructor(status: string | number = 500, responseText: string = '', response: Response) {
    super(status, responseText);

    this.status = status;
    this.response = response;
    this.responseText = responseText;
  }
}

export async function getErrorCode(err: HttpError): Promise<number | string> {
  let code = err.status || 500;

  if (err.response) {
    try {
      const json: unknown = await err.response.json();

      if (json && hasCode(json)) {
        code = Number(json.code);
      }
    } catch (err) {
      return code;
    }
  }

  return code;
}

export interface HttpRequest {
  body?: BodyInit;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  headers?: Record<string, string>;
  integrity?: string;
  keepalive?: boolean;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal | null;
  window?: unknown;
  url: string;
}
async function send<a>(
  type: t.Type<a, any, t.mixed>,
  { url, ...options }: HttpRequest,
): Promise<a> {
  const session = await getSession();

  let response: Response;
  try {
    response = await fetch(url, {
      credentials: 'omit',
      ...options,
    } as any);
  } catch (error) {
    applicationInsights.trackTrace(
      'API: request failed',
      {
        method: options.method,
        url,
        body: JSON.stringify(options.body),
        headers: JSON.stringify(options.headers),
        user: JSON.stringify(session?.user),
        error: (error as Error).toString(),
      },
      SeverityLevel.Error,
    );

    throw new ErrorCoded('request_failed', (error as Error).message);
  }
  const { status } = response;

  if (!response.ok) {
    if (status !== 500) {
      let responseJson: unknown;
      try {
        responseJson = await response.clone().json();
      } catch (error) {}

      if (hasCode(responseJson)) {
        applicationInsights.trackTrace(
          'API: returned error',
          {
            method: options.method,
            url,
            body: JSON.stringify(options.body),
            headers: JSON.stringify(options.headers),
            user: JSON.stringify(session?.user),
            status: status.toString(),
            code: responseJson.code.toString(),
            responseJson: JSON.stringify(responseJson),
          },
          SeverityLevel.Error,
        );

        throw new ErrorCoded(responseJson.code, responseJson.message || '');
      }
    }

    const responseText = await response.clone().text();

    applicationInsights.trackTrace(
      'API: call failed',
      {
        method: options.method,
        url,
        body: JSON.stringify(options.body),
        headers: JSON.stringify(options.headers),
        user: JSON.stringify(session?.user),
        status: status.toString(),
        responseText,
      },
      SeverityLevel.Error,
    );

    throw new ErrorCoded(`request_${status}`, responseText);
  }

  let data;
  if (type.name !== 'void') {
    try {
      data = await response.clone().json();
    } catch (error) {
      const responseText = await response.clone().text();

      applicationInsights.trackTrace(
        'API: parsing JSON failed',
        {
          method: options.method,
          url,
          body: JSON.stringify(options.body),
          headers: JSON.stringify(options.headers),
          user: JSON.stringify(session?.user),
          status: status.toString(),
          responseText,
          error: (error as Error).toString(),
        },
        SeverityLevel.Error,
      );

      throw new ErrorCoded(
        'request_parsing_response_failed',
        'Failed to parse the JSON response of the API call',
      );
    }
  }

  const validated = validate(type, data);
  if (!validated.isValid) {
    const responseText = await response.clone().text();
    const errorMessages = getErrorMessages(validated.errors);

    applicationInsights.trackTrace(
      'API: response type checking failed',
      {
        method: options.method,
        url,
        body: JSON.stringify(options.body),
        headers: JSON.stringify(options.headers),
        user: JSON.stringify(session?.user),
        status: status.toString(),
        responseText,
        error: JSON.stringify(errorMessages),
      },
      SeverityLevel.Critical,
    );

    console.error(`Invalid data returned from ${url}: ${errorMessages.join('\n')}`);
    throw new ErrorCoded(
      'request_type_check_response_failed',
      'Failed to type-check the payload of the response of the API call',
    );
  }

  return validated.data;
}

export async function get<a>(type: t.Type<a, any, t.mixed>, request: HttpRequest): Promise<a> {
  return send(type, {
    ...request,
    method: 'GET',
  });
}

interface PostRequest extends Omit<HttpRequest, 'body'> {
  body?: {};
}
export async function post<a>(type: t.Type<a, any, t.mixed>, request: PostRequest): Promise<a> {
  const requestBody = request.body;

  let body: string | undefined;
  if (requestBody) {
    body = JSON.stringify(requestBody);
  }

  return send(type, {
    ...request,
    body,
    method: 'POST',
  });
}

export async function put<a>(type: t.Type<a, any, t.mixed>, request: PostRequest): Promise<a> {
  const requestBody = request.body;

  let body: string | undefined;
  if (requestBody) {
    body = JSON.stringify(requestBody);
  }

  return send(type, {
    ...request,
    body,
    method: 'PUT',
  });
}

export async function del<a>(type: t.Type<a, any, t.mixed>, request: HttpRequest): Promise<a> {
  return send(type, {
    ...request,
    method: 'DELETE',
  });
}
