import Script from 'next/script';
const ANALYTICS_ID = 'UA-3864449-1';
const ADWORDS_ID = 'AW-1017592675';
const GTM = () => {
  return <>
      {/* Global site tag (gtag.js) - Google Analytics, Ads, Tag Manager */}
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`} async data-sentry-element="Script" data-sentry-source-file="GTM.tsx" />

      <Script id="gtag-init" dangerouslySetInnerHTML={{
      __html: ` 
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', '${ANALYTICS_ID}');
            gtag('config', '${ADWORDS_ID}');
          `
    }} data-sentry-element="Script" data-sentry-source-file="GTM.tsx" />
    </>;
};
export default GTM;