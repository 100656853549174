import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import classNames from 'clsx';
import { ReactNode } from 'react';
interface AppBarProps extends Omit<MuiAppBarProps, 'classes'> {
  classes?: {
    root?: string;
    toolbar?: string;
  };
  children: ReactNode;
}
const AppBar = ({
  children,
  classes = {},
  className,
  ...props
}: AppBarProps) => {
  return <MuiAppBar {...props} classes={{
    root: classNames(classes.root, className)
  }} data-sentry-element="MuiAppBar" data-sentry-component="AppBar" data-sentry-source-file="AppBar.tsx">
      <MuiToolbar classes={{
      root: classes.toolbar
    }} data-sentry-element="MuiToolbar" data-sentry-source-file="AppBar.tsx">{children}</MuiToolbar>
    </MuiAppBar>;
};
export default AppBar;