export interface Group {
  id: number;
  name: string;
  displayName: boolean;
  theme: {
    primaryColor: string;
    secondaryColor: string;
  };
  headerTheme: {
    primaryColor: string;
    secondaryColor: string;
  };
}

const groups: Group[] = [
  {
    id: 1,
    name: 'Particulier',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 6,
    name: 'TEST',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 23,
    name: 'GROUPE CASTORAMA',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 28,
    name: 'AMAZON',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 40,
    name: 'Pro à contacter',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 7,
    name: 'SCHMIDT',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 8,
    name: 'pro -20%',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 9,
    name: 'pro -10%',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 10,
    name: 'Pro -15%',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 11,
    name: 'Pro -12.5%',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 13,
    name: 'BRAVO',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 15,
    name: 'CUIS COULOIR',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 16,
    name: 'AMB. CUIS. CREATION',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 17,
    name: 'KH systeme',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 19,
    name: 'CHAB. ET DUVAL',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 20,
    name: 'ULTEI',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 21,
    name: 'CUISINELLA',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 22,
    name: 'PRO Sans RDV',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 24,
    name: 'C DISCOUNT',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 29,
    name: 'PERENE',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 30,
    name: 'MOBALPA',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 31,
    name: 'ARTHUR BONNET',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 34,
    name: 'EXPO CUISINISTE',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 35,
    name: 'Comera',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 36,
    name: 'Promoteur immobilier',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 37,
    name: 'art cuisine',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 38,
    name: 'pro-25%',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 39,
    name: 'SAV',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 41,
    name: 'PRO&CIE',
    displayName: true,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
  {
    id: 27,
    name: 'CUISINE PLUS',
    displayName: true,
    theme: {
      primaryColor: '#a50b30',
      secondaryColor: '#c18866',
    },
    headerTheme: {
      primaryColor: '#000',
      secondaryColor: '#a50b30',
    },
  },
  {
    id: 32,
    name: 'CUISINES REFERENCES',
    displayName: true,
    theme: {
      primaryColor: '#738e9b',
      secondaryColor: '#e95f4e',
    },
    headerTheme: {
      primaryColor: '#022c56',
      secondaryColor: '#dd222d',
    },
  },
  {
    id: 42,
    name: 'NOBLESSA',
    displayName: true,
    theme: {
      primaryColor: '#000',
      secondaryColor: '#60605e',
    },
    headerTheme: {
      primaryColor: '#fff',
      secondaryColor: '#60605e',
    },
  },
  {
    id: 12,
    name: 'IXINA',
    displayName: false,
    theme: {
      primaryColor: '#244b8f',
      secondaryColor: '#d93071',
    },
    headerTheme: {
      primaryColor: '#244b8f',
      secondaryColor: '#d93071',
    },
  },
  {
    id: 33,
    name: 'ECOCUISINE',
    displayName: false,
    theme: {
      primaryColor: '#4b5057',
      secondaryColor: '#d84200',
    },
    headerTheme: {
      primaryColor: '#fff',
      secondaryColor: '#d84200',
    },
  },
  {
    id: 48,
    name: 'WM88',
    displayName: false,
    theme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
    headerTheme: {
      primaryColor: '#588a7f',
      secondaryColor: '#deb772',
    },
  },
];

export default groups;
