import * as t from 'io-ts';

export const CartId = t.number;
export type CartId = t.TypeOf<typeof CartId>;

export const CartItemId = t.number;
export type CartItemId = t.TypeOf<typeof CartItemId>;

export const OrderId = t.number;
export type OrderId = t.TypeOf<typeof OrderId>;

export const GroupId = t.number;
export type GroupId = t.TypeOf<typeof GroupId>;

export const UserId = t.number;
export type UserId = t.TypeOf<typeof UserId>;

export const PromoCodeId = t.number;
export type PromoCodeId = t.TypeOf<typeof PromoCodeId>;

export const ProductBorderId = t.number;
export type ProductBorderId = t.TypeOf<typeof ProductBorderId>;
export const ProductOptionId = t.number;
export type ProductOptionId = t.TypeOf<typeof ProductOptionId>;
export const ProductId = t.number;
export type ProductId = t.TypeOf<typeof ProductId>;
export const CategoryId = t.number;
export type CategoryId = t.TypeOf<typeof CategoryId>;

export const AddressId = t.number;
export type AddressId = t.TypeOf<typeof AddressId>;
export const CountryId = t.number;
export type CountryId = t.TypeOf<typeof CountryId>;
export const ZoneId = t.number;
export type ZoneId = t.TypeOf<typeof ZoneId>;

export const ReviewId = t.number;
export type ReviewId = t.TypeOf<typeof ReviewId>;
