import * as t from 'io-ts';

export type UnPromisify<T> = T extends Promise<infer U> ? U : T;

type Primitive = string | boolean | number | null | undefined;
type JsonValue =
  | Primitive
  | Array<Primitive | { [key: string]: JsonValue }>
  | { [key: string]: JsonValue };

export type JsonObject<Keys extends string> = Partial<Record<Keys, JsonValue>>;

export const TranslatedText = t.record(t.string, t.union([t.string, t.undefined]));
export type TranslatedText = t.TypeOf<typeof TranslatedText>;
export const TranslatedTexts = t.array(TranslatedText);
export type TranslatedTexts = t.TypeOf<typeof TranslatedTexts>;

export const Image = t.intersection([
  t.type({
    src: t.string,
    alt: TranslatedText,
  }),
  t.partial({
    id: t.string,
    title: TranslatedText,
    width: t.number,
    height: t.number,
  }),
]);
export type Image = t.TypeOf<typeof Image>;
export const Images = t.array(Image);
export type Images = t.TypeOf<typeof Images>;
