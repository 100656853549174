import * as t from 'io-ts';

import { Image, Images, TranslatedText } from './globals';
import { ProductBorderId, ProductId, ProductOptionId } from './ids';

export { ProductId, ProductOptionId, ProductBorderId };

export const ProductBorder = t.strict({
  mustBeFolded: t.boolean,
});
export type ProductBorder = t.TypeOf<typeof ProductBorder>;
export const ProductBorders = t.array(ProductBorder);
export type ProductBorders = t.TypeOf<typeof ProductBorders>;

export const ProductCondition = t.strict({
  input: t.string,
  checker: t.union([t.literal('='), t.literal('!=')]),
  value: t.union([t.string, t.number, t.boolean]),
});
export type ProductCondition = t.TypeOf<typeof ProductCondition>;

const ProductInputBase = t.intersection([
  t.type({
    name: t.string,
    label: TranslatedText,
    description: TranslatedText,
    images: Images,
  }),
  t.partial({
    required: t.boolean,
    disabled: t.boolean,
    priceEquation: t.union([t.string, t.null]),
    weightEquation: t.union([t.string, t.null]),
    conditions: t.array(ProductCondition),
    repeat: t.union([t.keyof({ borders: null }), t.null]),
  }),
]);
export const ProductInputTypeHidden = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('hidden'),
    value: t.string,
  }),
]);
export type ProductInputTypeHidden = t.TypeOf<typeof ProductInputTypeHidden>;
export const ProductInputTypeCheckbox = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('checkbox'),
    checked: t.boolean,
    checkedLabel: TranslatedText,
    uncheckedLabel: TranslatedText,
  }),
]);
export type ProductInputTypeCheckbox = t.TypeOf<typeof ProductInputTypeCheckbox>;
export const ProductInputTypeSwitch = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('switch'),
    checked: t.boolean,
    checkedLabel: TranslatedText,
    uncheckedLabel: TranslatedText,
  }),
]);
export type ProductInputTypeSwitch = t.TypeOf<typeof ProductInputTypeSwitch>;
export const ProductInputTypeNumber = t.intersection([
  ProductInputBase,
  t.intersection([
    t.type({
      type: t.literal('number'),
      value: t.union([t.number, t.null]),
      min: t.union([t.number, t.null]),
      max: t.union([t.number, t.null]),
    }),
    t.partial({
      placeholder: TranslatedText,
    }),
  ]),
]);
export type ProductInputTypeNumber = t.TypeOf<typeof ProductInputTypeNumber>;
export const ProductInputOption = t.intersection([
  t.type({
    text: TranslatedText,
    value: t.string,
  }),
  t.partial({
    disabled: t.boolean,
    priceBase: t.union([t.number, t.null]),
    priceEquation: t.union([t.string, t.null]),
    weightEquation: t.union([t.string, t.null]),
    conditions: t.array(ProductCondition),
    image: t.union([Image, t.null]),
    description: TranslatedText,
  }),
]);
export type ProductInputOption = t.TypeOf<typeof ProductInputOption>;
export const ProductInputTypeSelect = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('select'),
    value: t.string,
    options: t.array(ProductInputOption),
  }),
]);
export type ProductInputTypeSelect = t.TypeOf<typeof ProductInputTypeSelect>;
export const ProductInputTypeRadio = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('radio'),
    value: t.string,
    options: t.array(ProductInputOption),
  }),
]);
export type ProductInputTypeRadio = t.TypeOf<typeof ProductInputTypeRadio>;
export const ProductInputTypeGallery = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('gallery'),
    value: t.string,
    options: t.array(ProductInputOption),
  }),
]);
export type ProductInputTypeGallery = t.TypeOf<typeof ProductInputTypeGallery>;
export const ProductInputTypeTable = t.intersection([
  ProductInputBase,
  t.type({
    type: t.literal('table'),
    x: t.strict({
      name: t.string,
      text: TranslatedText,
      value: t.number,
      values: t.array(t.number),
    }),
    y: t.strict({
      name: t.string,
      text: TranslatedText,
      value: t.number,
      values: t.array(t.number),
    }),
  }),
]);
export type ProductInputTypeTable = t.TypeOf<typeof ProductInputTypeTable>;
export const ProductInput = t.taggedUnion('type', [
  ProductInputTypeHidden,
  ProductInputTypeCheckbox,
  ProductInputTypeSwitch,
  ProductInputTypeNumber,
  ProductInputTypeSelect,
  ProductInputTypeRadio,
  ProductInputTypeTable,
  ProductInputTypeGallery,
]);
export type ProductInput = t.TypeOf<typeof ProductInput>;

export const CreateProductOption = t.intersection([
  t.type({
    formName: t.string,
    name: TranslatedText,
    images: Images,
    description: TranslatedText,
    inputs: t.array(ProductInput),
  }),
  t.partial({
    subheader: TranslatedText,
    priceReference: t.union([t.literal('B'), t.literal('W'), t.null]),
    priceEquation: t.union([t.string, t.null]),
    weightEquation: t.union([t.string, t.null]),
    repeat: t.union([
      t.strict({
        type: t.literal('option'),
        images: Images,
      }),
      t.null,
    ]),
  }),
]);
export type CreateProductOption = t.TypeOf<typeof CreateProductOption>;

export const ProductOption = t.intersection([t.type({ id: ProductOptionId }), CreateProductOption]);
export type ProductOption = t.TypeOf<typeof ProductOption>;
export const ProductOptions = t.array(ProductOption);
export type ProductOptions = t.TypeOf<typeof ProductOptions>;

const ProductAvailabilityType = t.union([
  t.literal('hours'),
  t.literal('days'),
  t.literal('weeks'),
  t.literal('months'),
]);
export type ProductAvailabilityType = t.TypeOf<typeof ProductAvailabilityType>;
export const CreateProduct = t.type({
  name: TranslatedText,
  description: t.strict({
    meta: TranslatedText,
    header: TranslatedText,
    footer: TranslatedText,
    category: TranslatedText,
  }),
  images: t.array(t.intersection([Image, t.partial({ embedUrl: t.string })])),
  cover: t.union([Image, t.null]),
  widthEquation: t.union([t.string, t.null]),
  heightEquation: t.union([t.string, t.null]),
  areaEquation: t.union([t.string, t.null]),
  perimeterEquation: t.union([t.string, t.null]),
  thicknessEquation: t.union([t.string, t.null]),
  weightEquation: t.union([t.string, t.null]),
  priceBase: t.union([t.number, t.null]),
  borders: t.array(ProductBorder),
  options: t.array(t.number),
  availability: t.union([
    t.literal('in-stock'),
    t.strict({
      min: t.union([t.number, t.null]),
      max: t.union([t.number, t.null]),
      type: ProductAvailabilityType,
    }),
    t.null,
  ]),
  model: t.union([t.string, t.null]),
  priceReference: t.union([t.literal('B'), t.literal('W'), t.null]),
  priceEquation: t.union([t.string, t.null]),
});
export type CreateProduct = t.TypeOf<typeof CreateProduct>;
export const Product = t.intersection([t.type({ id: ProductId }), CreateProduct]);
export type Product = t.TypeOf<typeof Product>;

export const Products = t.array(Product);
export type Products = t.TypeOf<typeof Products>;

export const CalculatedProductInfo = t.strict({
  width: t.number,
  height: t.number,
  thick: t.number,
  perimeter: t.number,
  area: t.number,
  productPrice: t.number,
  optionsPrice: t.number,
  productWeight: t.number,
  optionsWeight: t.number,
});
export type CalculatedProductInfo = t.TypeOf<typeof CalculatedProductInfo>;

export const InputValue = t.union([
  t.null,
  t.string,
  t.number,
  t.boolean,
  t.record(t.string, t.number),
]);
export type InputValue = t.TypeOf<typeof InputValue>;

export const BorderInputValue = t.record(t.string, InputValue); // Border options
export type BorderInputValue = t.TypeOf<typeof BorderInputValue>;
export const OptionInputValue = t.union([InputValue, BorderInputValue]);
export type OptionInputValue = t.TypeOf<typeof OptionInputValue>;

export const StandardOptionValues = t.record(t.string, OptionInputValue);
export type StandardOptionValues = t.TypeOf<typeof StandardOptionValues>;
export const RepeatOptionValues = t.array(StandardOptionValues); // repeat options (e.g. plugs)
export type RepeatOptionValues = t.TypeOf<typeof RepeatOptionValues>;

export const OptionValues = t.union([RepeatOptionValues, StandardOptionValues]);
export type OptionValues = t.TypeOf<typeof OptionValues>;

export const OptionsValues = t.record(t.string, OptionValues);
export type OptionsValues = t.TypeOf<typeof OptionsValues>;
