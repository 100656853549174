import * as t from 'io-ts';

const httpsErrors = {
  request_400: null,
  request_401: null,
  request_403: null,
  request_404: null,
  request_409: null,
  page_regeneration_failed: null,
  request_500: null,
  promo_code_not_found: null,
  user_create_already_exists: null,
  order_create_email_required: null,
};

export const HttpErrorData = t.intersection([
  t.strict({ code: t.keyof(httpsErrors) }),
  t.partial({ message: t.string }),
]);
export type HttpErrorData = t.TypeOf<typeof HttpErrorData>;
