'use client';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'client/utils/i18n';
export const LocalizationProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    t
  } = useTranslation();
  const localeText = useMemo(() => ({
    cancelButtonLabel: t('main:cancel'),
    okButtonLabel: t('main:ok')
  }), [t]);
  return <MuiLocalizationProvider dateAdapter={AdapterDateFns} localeText={localeText} data-sentry-element="MuiLocalizationProvider" data-sentry-component="LocalizationProvider" data-sentry-source-file="LocalizationProvider.tsx">
      {children}
    </MuiLocalizationProvider>;
};