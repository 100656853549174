import { UserAgent } from 'express-useragent';
import Image from 'next/image';
import React, { ReactNode } from 'react';
const BrowserChecker = ({
  children
}: {
  children: ReactNode;
}) => {
  const userAgent = React.useMemo(() => {
    const windowUserAgent = typeof window !== 'undefined' ? window.navigator.userAgent : null;
    return new UserAgent().parse(windowUserAgent || '');
  }, []);
  const isBrowserSupported = !userAgent.isIE || typeof userAgent.version === 'number' && userAgent.version < 11;
  if (isBrowserSupported) {
    return <>{children}</>;
  }
  return <div style={{
    background: '#fafafa'
  }} data-sentry-component="BrowserChecker" data-sentry-source-file="BrowserChecker.tsx">
      <style>{`.browserImage { margin: -5px 10px 0 0; float: left; }`}</style>
      <div style={{
      width: '50%',
      margin: 'auto',
      fontFamily: 'verdanda, sans-serif',
      color: 'rgb(0,0,0,0.70)',
      fontSize: '1.5em'
    }}>
        <div>
          <div>
            <h2 style={{
            color: 'rgb(0,0,0,0.80)',
            textAlign: 'center',
            fontSize: '2em'
          }}>
              Votre navigateur Web n&apos;est plus compatible
            </h2>
            <p>
              <span style={{
              fontVariant: 'small-caps',
              fontWeight: 900
            }}>
                credence-inox.com
              </span>{' '}
              fonctionne avec de nombreux navigateurs. Cependant, afin de{' '}
              <b>garantir votre sécurité</b>, vous devez passer à un navigateur récent.
            </p>
            <p>Vous trouverez ci-dessous les dernières versions des navigateurs compatibles. </p>
            <p style={{
            color: 'rgb(0,0,0,0.65)',
            fontSize: '0.7em',
            fontStyle: 'italic',
            fontWeight: 'lighter'
          }}>
              Ce message se base sur la chaîne user-agent renvoyée par votre navigateur. Les
              extensions et les plug-ins que vous avez installés peuvent modifier la chaîne
              user-agent. Voici ce que nous avons reçu : <code>{userAgent.source}</code>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h6>Téléchargez la dernière version</h6>

            <div id="google" style={{
            display: 'block',
            width: '150px',
            padding: '6px',
            marginTop: '15px',
            marginRight: '14px',
            float: 'left',
            fontSize: '11px',
            fontStyle: 'italic',
            background: 'white',
            borderRadius: '3px',
            borderBottom: '2px solid #ccc',
            textAlign: 'left'
          }}>
              <h3 style={{
              margin: 0,
              paddingBottom: '5px',
              fontSize: '13px',
              fontWeight: 500
            }}>
                <a style={{
                textDecoration: 'none',
                margin: 0,
                color: '#167ac6'
              }} href="http://www.google.com/chrome/index.html">
                  <Image width={45} height={45} className="browserLogo" src={`/static/img/download-chrome.png`} alt="Chrome logo" data-sentry-element="Image" data-sentry-source-file="BrowserChecker.tsx" />{' '}
                  Chrome{' '}
                </a>
              </h3>
              <p style={{
              padding: 0,
              margin: 0
            }}>de Google </p>
            </div>

            <div id="ie" style={{
            display: 'block',
            width: '150px',
            padding: '6px',
            marginTop: '15px',
            marginRight: '14px',
            float: 'left',
            fontSize: '11px',
            fontStyle: 'italic',
            background: 'white',
            borderRadius: '3px',
            borderBottom: '2px solid #ccc',
            textAlign: 'left'
          }}>
              <h3 style={{
              margin: 0,
              paddingBottom: '5px',
              fontSize: '13px',
              fontWeight: 500
            }}>
                <a style={{
                textDecoration: 'none',
                margin: 0,
                color: '#167ac6'
              }} href="http://windows.microsoft.com/ie">
                  <Image width={45} height={45} className="browserLogo" src={`/static/img/download-ie.jpg`} alt="IE logo" data-sentry-element="Image" data-sentry-source-file="BrowserChecker.tsx" />{' '}
                  Internet Explorer{' '}
                </a>
              </h3>
              <p style={{
              padding: 0,
              margin: 0
            }}>de Microsoft </p>
            </div>
            <div id="firefox" style={{
            display: 'block',
            width: '150px',
            padding: '6px',
            marginTop: '15px',
            float: 'left',
            fontSize: '11px',
            fontStyle: 'italic',
            background: 'white',
            borderRadius: '3px',
            borderBottom: '2px solid #ccc',
            textAlign: 'left',
            marginRight: '14px'
          }}>
              <h3 style={{
              margin: 0,
              paddingBottom: '5px',
              fontSize: '13px',
              fontWeight: 500
            }}>
                <a style={{
                textDecoration: 'none',
                margin: 0,
                color: '#167ac6'
              }} href="https://www.mozilla.org/firefox/new">
                  <Image width={45} height={45} className="browserLogo" src={`/static/img/download-firefox.png`} alt="Firefox logo" data-sentry-element="Image" data-sentry-source-file="BrowserChecker.tsx" />{' '}
                  Firefox{' '}
                </a>
              </h3>
              <p style={{
              padding: 0,
              margin: 0
            }}>de Mozilla </p>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default BrowserChecker;