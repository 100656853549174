import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiSlide from '@mui/material/Slide';
import { TransitionProps as MuiTransitionProps } from '@mui/material/transitions';
import React from 'react';
import AppBar from './AppBar';
import styles from './DialogFullScreen.module.css';
import IconButton from './IconButton';
import { CloseIcon } from './Icons';
import Typography from './Typography';
const Transition = React.forwardRef(function Transition(props: MuiTransitionProps & {
  children: React.ReactElement<any, any>;
}, ref: React.Ref<unknown>) {
  return <MuiSlide direction="up" ref={ref} {...props} />;
});
interface Props extends MuiDialogProps {
  enableClose?: boolean;
  component: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  header?: React.ReactNode;
}
const DialogFullScreen = ({
  component: Component,
  header,
  children,
  onClose,
  enableClose = true,
  ...props
}: Props) => {
  return <MuiDialog TransitionComponent={Transition} fullScreen={true} onClose={onClose} {...props} data-sentry-element="MuiDialog" data-sentry-component="DialogFullScreen" data-sentry-source-file="DialogFullScreen.tsx">
      <AppBar position="sticky" data-sentry-element="AppBar" data-sentry-source-file="DialogFullScreen.tsx">
        {enableClose && <IconButton color="inherit" onClick={onClose as any} size="large">
            <CloseIcon />
          </IconButton>}
        {header && <Typography variant="h2" color="inherit">
            {header}
          </Typography>}
      </AppBar>
      <Component className={styles.content} data-sentry-element="Component" data-sentry-source-file="DialogFullScreen.tsx">{children}</Component>
    </MuiDialog>;
};
export default DialogFullScreen;