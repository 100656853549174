import { useSession } from 'next-auth/react';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useProductCalculatedInfo } from 'client/services/products';
import { CalculatedProductInfo } from 'types/products';
import { getProductPrice } from 'utils/product';
import useCart, { CartItem } from './useCart';
type LoadingResult<DEFAULT_DATA> = {
  data: DEFAULT_DATA;
  isLoading: true;
  error: null;
  refetch: () => void;
};
type ErrorResult<DEFAULT_DATA> = {
  data: DEFAULT_DATA;
  isLoading: false;
  error: Error;
  refetch: () => void;
};
type SuccessResult<SUCCESS_DATA> = {
  data: SUCCESS_DATA;
  isLoading: false;
  error: null;
  refetch: () => void;
};
type ContextItem<SUCCESS_DATA extends {}, DEFAULT_DATA = null> = LoadingResult<DEFAULT_DATA> | ErrorResult<DEFAULT_DATA> | SuccessResult<SUCCESS_DATA>;
type CartItemWithDetails = ContextItem<CartItem & CalculatedProductInfo, CartItem>;
const CalculatedCartItemsDetailsContext = createContext<CartItemWithDetails[]>([]);
const CartItemDetailsFetcher = ({
  cartItemWithDetails,
  setCartItemsWithDetails
}: {
  cartItemWithDetails: CartItemWithDetails;
  setCartItemsWithDetails: (setter: (previousData: CartItemWithDetails[]) => CartItemWithDetails[]) => void;
}) => {
  const cartItemDetailsResult = useProductCalculatedInfo(cartItemWithDetails.data.productId, cartItemWithDetails.data.optionsValues);
  useEffect(() => {
    setCartItemsWithDetails(previousCartItems => previousCartItems.map(cartItem => {
      if (cartItem.data.id !== cartItemWithDetails.data.id) return cartItem;
      if (cartItemDetailsResult.isIdle || cartItemDetailsResult.isLoading) {
        return {
          isLoading: true,
          error: null,
          refetch: cartItemDetailsResult.refetch,
          data: cartItem.data
        };
      }
      if (cartItemDetailsResult.error) {
        return {
          isLoading: false,
          error: cartItemDetailsResult.error,
          refetch: cartItemDetailsResult.refetch,
          data: cartItem.data
        };
      }
      return {
        isLoading: false,
        error: null,
        refetch: cartItemDetailsResult.refetch,
        data: {
          ...cartItem.data,
          ...cartItemDetailsResult.data
        }
      };
    }));
  }, [cartItemDetailsResult.data, cartItemDetailsResult.error, cartItemDetailsResult.isIdle, cartItemDetailsResult.isLoading, cartItemDetailsResult.refetch, cartItemWithDetails.data.id, setCartItemsWithDetails]);
  return null;
};
export const CalculatedCartItemsDetailsProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    cartItems
  } = useCart();
  const [cartItemsWithDetails, setCartItemsWithDetails] = useState<CartItemWithDetails[]>([]);
  useEffect(() => {
    setCartItemsWithDetails(previousCartItems => {
      return cartItems.map<CartItemWithDetails>(cartItem => {
        const previousCartItem = previousCartItems.find(({
          data
        }) => data.id === cartItem.id);
        if (!previousCartItem) {
          return {
            data: cartItem,
            isLoading: true,
            error: null,
            refetch: () => null
          };
        }
        if (previousCartItem.data.productId === cartItem.productId && previousCartItem.data.optionsValues === cartItem.optionsValues && previousCartItem.data.quantity === cartItem.quantity) return previousCartItem;
        return {
          ...previousCartItem,
          data: {
            ...previousCartItem.data,
            ...cartItem
          }
        } as CartItemWithDetails;
      });
    });
  }, [cartItems]);
  return <CalculatedCartItemsDetailsContext.Provider value={cartItemsWithDetails} data-sentry-element="unknown" data-sentry-component="CalculatedCartItemsDetailsProvider" data-sentry-source-file="useCalculatedCartItemsDetails.tsx">
      {cartItemsWithDetails.map(cartItemWithDetails => <CartItemDetailsFetcher key={cartItemWithDetails.data.id} cartItemWithDetails={cartItemWithDetails} setCartItemsWithDetails={setCartItemsWithDetails} />)}
      {children}
    </CalculatedCartItemsDetailsContext.Provider>;
};
export type CartItemWithCalculatedDetails = ContextItem<CartItem & CalculatedProductInfo & {
  thickness: number;
  weight: number;
  price: {
    subTotal: number;
    tax: number;
    total: number;
  };
}, CartItem>;
interface UseCartItemsCalculatedDetailsParams {
  applyMargin: boolean;
  deliveryCountryId?: number;
  taxNumber?: string;
}
const useCartItemsWithCalculatedDetails = ({
  applyMargin,
  deliveryCountryId,
  taxNumber
}: UseCartItemsCalculatedDetailsParams): CartItemWithCalculatedDetails[] => {
  const session = useSession();
  const cartItemsWithDetails = useContext(CalculatedCartItemsDetailsContext);
  return useMemo((): CartItemWithCalculatedDetails[] => {
    return cartItemsWithDetails.map<CartItemWithCalculatedDetails>(cartItemWithDetails => {
      if (cartItemWithDetails.isLoading) return cartItemWithDetails;
      if (cartItemWithDetails.error) return cartItemWithDetails;
      const price = getProductPrice(cartItemWithDetails.data.productPrice, cartItemWithDetails.data.optionsPrice, {
        productsDiscount: session.data?.user.productsDiscount,
        optionsDiscount: session.data?.user.optionsDiscount,
        margin: applyMargin ? session.data?.user.margin : undefined,
        deliveryCountryId,
        taxNumber
      });
      return {
        isLoading: false,
        error: null,
        refetch: cartItemWithDetails.refetch,
        data: {
          ...cartItemWithDetails.data,
          thickness: cartItemWithDetails.data.thick,
          weight: (cartItemWithDetails.data.productWeight + cartItemWithDetails.data.optionsWeight) * cartItemWithDetails.data.quantity,
          price: {
            subTotal: price.subTotal * cartItemWithDetails.data.quantity,
            tax: price.tax * cartItemWithDetails.data.quantity,
            total: price.total * cartItemWithDetails.data.quantity
          }
        }
      };
    });
  }, [applyMargin, deliveryCountryId, cartItemsWithDetails, taxNumber, session.data?.user.margin, session.data?.user.optionsDiscount, session.data?.user.productsDiscount]);
};
export default useCartItemsWithCalculatedDetails;