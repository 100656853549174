import * as t from 'io-ts';
import { stringify } from 'qs';

import {
  useDeleteAPI,
  useGetAPI,
  usePostAPI,
  usePutAPI,
  useQueryClient,
} from 'client/hooks/useAPI';

import {
  CalculatedProductInfo,
  CreateProduct,
  OptionsValues,
  Product,
  ProductId,
  ProductOptions,
  Products,
} from 'types/products';

const queryKeys = {
  adminProducts: ['admin-products'],
  adminProduct: (productId: ProductId) => ['admin-products', productId],
  product: (productId: ProductId) => ['products', productId],
  productOptions: (productId: ProductId) => ['products', productId, 'options'],
  productCalculatedInfo: (productId: ProductId, optionsValues: OptionsValues) => [
    'products',
    productId,
    'info',
    { optionsValues },
  ],
};

export function useAdminProducts() {
  return useGetAPI(`/api/admin/products`, {
    type: Products,
    queryKey: queryKeys.adminProducts,
  });
}

export function useAdminProduct(productId: ProductId) {
  return useGetAPI(`/api/admin/products/${productId}`, {
    type: Product,
    queryKey: queryKeys.adminProduct(productId),
  });
}

export function useProduct(productId: ProductId) {
  return useGetAPI(`/api/products/${productId}`, {
    type: Product,
    queryKey: queryKeys.product(productId),
  });
}

export function useAdminCreateProduct() {
  const queryClient = useQueryClient();
  return usePostAPI<CreateProduct, Product>(`/api/admin/products`, {
    type: Product,
    onSuccess: newProduct => {
      queryClient.setQueryData<Product>(queryKeys.adminProduct(newProduct.id), newProduct);

      queryClient.setQueryData<Products>(queryKeys.adminProducts, (previousProducts = []) => [
        ...previousProducts,
        newProduct,
      ]);
    },
  });
}

export function useAdminUpdateProduct(productId: ProductId) {
  const queryClient = useQueryClient();
  return usePutAPI<Product, Product>(`/api/admin/products/${productId}`, {
    type: Product,
    onSuccess: updatedProduct => {
      queryClient.setQueryData<Product>(queryKeys.adminProduct(updatedProduct.id), updatedProduct);

      queryClient.setQueryData<Products>(queryKeys.adminProducts, (previousProducts = []) =>
        previousProducts.map(previousProduct =>
          previousProduct.id === productId ? updatedProduct : previousProduct,
        ),
      );
    },
  });
}

export function useAdminDeleteProduct(productId: ProductId) {
  const queryClient = useQueryClient();
  return useDeleteAPI(`/api/admin/products/${productId}`, {
    type: t.void,
    onSuccess: () => {
      queryClient.setQueryData<ProductOptions>(
        queryKeys.adminProducts,
        products => products?.filter(product => product.id !== productId) ?? [],
      );

      queryClient.invalidateQueries(queryKeys.adminProduct(productId));
    },
  });
}

export function useProductOptions(productId: ProductId) {
  return useGetAPI(`/api/products/${productId}/options`, {
    type: ProductOptions,
    queryKey: queryKeys.productOptions(productId),
  });
}

export function useProductCalculatedInfo(productId: ProductId, optionsValues: OptionsValues) {
  return useGetAPI(
    `/api/products/${productId}/price?${stringify(
      {
        optionsValues: JSON.stringify(optionsValues),
      },
      { encode: false },
    )}`,
    {
      type: CalculatedProductInfo,
      queryKey: queryKeys.productCalculatedInfo(productId, optionsValues),
    },
  );
}
