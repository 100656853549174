export interface CalculateTaxOptions {
  deliveryCountryId?: number;
  taxNumber?: string;
}

export function calculateTax(
  priceExclTax: number,
  { deliveryCountryId, taxNumber }: CalculateTaxOptions = {},
) {
  const taxRate = getTaxRate(deliveryCountryId, taxNumber);

  return { taxRate, taxAmount: Math.round(priceExclTax * taxRate * 100) / 100 };
}

export function getSubTotalAndTax(
  totalPrice: number,
  { deliveryCountryId, taxNumber }: CalculateTaxOptions = {},
) {
  const taxRate = getTaxRate(deliveryCountryId, taxNumber);
  const priceWithoutTax = Math.round((totalPrice / (1 + taxRate)) * 100) / 100;

  return { subTotal: priceWithoutTax, tax: totalPrice - priceWithoutTax, total: totalPrice };
}

export function getTaxRate(deliveryCountryId?: number, taxNumber?: string) {
  return isCustomerTaxable(deliveryCountryId, taxNumber) ? 0.2 : 0;
}
const France = 73;
const EuropeanCountries = [
  14, // Austria
  21, // Belgium
  33, // Bulgaria
  53, // Croatia
  55, // Cyprus
  56, // CzechRepublic
  57, // Denmark
  67, // Estonia
  72, // Finland
  73, // France
  81, // Germany
  84, // Greece
  97, // Hungary
  103, // Ireland
  105, // Italy
  117, // Latvia
  123, // Lithuania
  124, // Luxembourg
  132, // Malta
  150, // Netherlands
  170, // Poland
  171, // Portugal
  175, // Romania
  189, // Slovakia
  190, // Slovenia
  195, // Spain
  203, // Sweden
];
function isCustomerTaxable(deliveryCountryId?: number, taxNumber?: string) {
  if (!deliveryCountryId) return true;
  if (deliveryCountryId === France) return true;
  if (EuropeanCountries.includes(deliveryCountryId) && !taxNumber) return true;
  if (EuropeanCountries.includes(deliveryCountryId) && taxNumber) return false;
  return false;
}
