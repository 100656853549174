import { useSession } from 'next-auth/react';
import groups, { Group } from 'data/groups';
const DEFAULT_GROUP_ID = 1;
const defaultGroup = groups.find(({
  id
}) => id === DEFAULT_GROUP_ID);
if (!defaultGroup) throw new Error('Default group not found');
export function getGroup(groupId?: number) {
  return groups.find(({
    id
  }) => id === groupId);
}
const useUserGroup = (): Readonly<Group> => {
  const session = useSession();
  let group: Group | undefined;
  if (typeof session.data?.user.groupId === 'number') {
    group = getGroup(session.data.user.groupId);
  }
  return group || defaultGroup;
};
export default useUserGroup;