import React from 'react';

import { useGetAPI, useQueryClient } from 'client/hooks/useAPI';

import { HttpRequest, del, post, put } from 'client/utils/http';

import { AddCartItem, Cart } from 'types/cart';
import { CartItemId, UserId } from 'types/ids';

const queryKeys = {
  cart: (userId?: number) => ['cart', userId],
};

export function useCart(userId?: number) {
  return useGetAPI(`/api/users/${userId}/cart`, {
    type: Cart,
    queryKey: queryKeys.cart(userId),
    shouldFetch: Boolean(userId),
  });
}

export function useInvalidateCart() {
  const queryClient = useQueryClient();

  return React.useCallback(
    (userId: number) => queryClient.invalidateQueries(queryKeys.cart(userId)),
    [queryClient],
  );
}

export async function addCartItem(
  userId: UserId,
  body: AddCartItem,
  { url = '', headers = {}, ...requestOptions }: Partial<HttpRequest> = {},
) {
  return await post(Cart, {
    ...requestOptions,
    url: `${url}/api/users/${userId}/cart`,
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: 'include',
    body,
  });
}

export async function updateCartItem(
  userId: UserId,
  cartItemId: CartItemId,
  body: AddCartItem,
  { url = '', headers = {}, ...requestOptions }: Partial<HttpRequest> = {},
) {
  return await put(Cart, {
    ...requestOptions,
    url: `${url}/api/users/${userId}/cart/${cartItemId}`,
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: 'include',
    body,
  });
}

export async function removeCartItem(
  userId: UserId,
  cartItemId: CartItemId,
  { url = '', headers = {}, ...requestOptions }: Partial<HttpRequest> = {},
) {
  return await del(Cart, {
    ...requestOptions,
    url: `${url}/api/users/${userId}/cart/${cartItemId}`,
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: 'include',
  });
}
