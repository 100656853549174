import * as t from 'io-ts';

import { CartId } from './ids';
import { OptionsValues, ProductId } from './products';

export type CartPrice = {
  productsAmount: number;
  productsPromo: number;
  productsSubTotal: number;
  productsTax: number;
  promoTax: number;
  shippingAmount: number;
  shippingPromo: number;
  shippingSubTotal: number;
  shippingTax: number;
  total: number;
};

export type ProductPrice = {
  id: number;
  subTotal: number;
  tax: number;
  total: number;
};
export type ProductsPrices = ProductPrice[];

export const CartItemWithoutId = t.strict({
  productId: ProductId,
  optionsValues: OptionsValues,
  quantity: t.number,
});
export type CartItemWithoutId = t.TypeOf<typeof CartItemWithoutId>;
export const CartItem = t.intersection([
  CartItemWithoutId,
  t.strict({
    id: t.number,
  }),
]);
export type CartItem = t.TypeOf<typeof CartItem>;
export const CartItemWithDetails = t.intersection([
  CartItem,
  t.strict({
    width: t.number,
    height: t.number,
    thickness: t.number,
    weight: t.number,
    productPrice: t.number,
    optionsPrice: t.number,
  }),
]);
export type CartItemWithDetails = t.TypeOf<typeof CartItemWithDetails>;
export const CartItemWithDetailsAndPrices = t.intersection([
  CartItemWithDetails,
  t.strict({
    price: t.strict({
      product: t.number,
      options: t.number,
      subTotal: t.number,
      tax: t.number,
      total: t.number,
    }),
  }),
]);
export type CartItemWithDetailsAndPrices = t.TypeOf<typeof CartItemWithDetailsAndPrices>;

export const Cart = t.strict({
  id: CartId,
  userId: t.number,

  name: t.string,

  dateAdded: t.string,
  dateModified: t.string,

  items: t.array(CartItemWithoutId),
});
export type Cart = t.TypeOf<typeof Cart>;
export const Carts = t.array(Cart);
export type Carts = t.TypeOf<typeof Carts>;

export const SaveCart = t.strict({
  name: t.string,
  items: t.array(CartItemWithoutId),
});
export type SaveCart = t.TypeOf<typeof SaveCart>;
