export type Locale = 'fr' | 'en';

export const locales = [
  'fr',
  // 'en'
] as const;
export const defaultLocale: Locale = 'fr';
export const localeToTimeZone = {
  fr: 'Europe/Paris',
  en: 'Europe/London',
} as const satisfies Record<Locale, string>;
